import { ERROR, SUCCESS } from "../../../Constants/Labels";
import { CART_ID } from "../../../Constants/LocalStorage";
import { addProductToWishlist, addProductToWishlistItem, createEmptCart, getCartCountDetail, getMiniCartDetail, getWishlistItems, mergerCart, removeFromWishlist } from "../../../Services/graphQL.service";
import { getSessionItem, setSessionItem, showToast } from "../../../Utility/Utilities";
import { getCartAction, handleCartError } from "./CartPageActions";
import { GET_CART_COUNT_SUCCESS, GET_CART_COUNT_FAILED, GET_MINI_CART_SUCCESS, GET_MINI_CART_FAILED, CREATE_EMPTY_CART_SUCCESS, CREATE_EMPTY_CART_ERROR, GET_WISHLIST_ERROR, GET_WISHLIST_SUCCESS, ADD_TO_WISHLIST_REQUEST, DELETE_WISHLIST_ERROR, ADD_TO_WISHLIST_ERROR, GET_CART_PAGE_SUCCESS, ADD_TO_WISHLIST_SUCCESS } from "./CartPageTypes";

export const getCartCountAction = () => {
    return async (dispatch: any) => {
        // dispatch({ type: GET_CART_PAGE_REQUEST });
        try {
            const cartId = getSessionItem(CART_ID);
            if (cartId) {
                const { data: response } = await getCartCountDetail(cartId);
                dispatch(getMiniCartAction())
                if (response && response.errors) {
                    if (
                        response.errors[0].message ===
                        `Some item options or their combination are not currently available.` ||
                        response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
                    ) {
                        dispatch({
                            type: GET_CART_COUNT_SUCCESS,
                            payload: {
                                type: GET_CART_COUNT_SUCCESS,
                                data: response?.data?.cart?.total_quantity,
                            },
                        });
                    } else {
                        handleCartError(response, dispatch);
                    }
                } else {
                    dispatch({
                        type: GET_CART_COUNT_SUCCESS,
                        payload: {
                            type: GET_CART_COUNT_SUCCESS,
                            data: response?.data?.cart?.total_quantity,
                        },
                    });
                }
            }
        } catch (error) {
            dispatch({
                type: GET_CART_COUNT_FAILED,
                message: error,
            });
        }
    };
};

export const getMiniCartAction = () => {
    return async (dispatch: any) => {
        try {
            const cartId = getSessionItem(CART_ID);
            if (cartId) {
                const { data: response } = await getMiniCartDetail(cartId);
                if (response && response.errors) {
                    if (
                        response.errors[0].message ===
                        `Some item options or their combination are not currently available.` ||
                        response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
                    ) {
                        dispatch({
                            type: GET_MINI_CART_SUCCESS,
                            payload: {
                                type: GET_MINI_CART_SUCCESS,
                                data: response?.data?.cart,
                                error: true
                            },
                        });
                    } else {
                        dispatch({
                            type: GET_MINI_CART_FAILED,
                            message: response.errors[0].message,
                        });
                        handleCartError(response, dispatch);
                    }
                } else {
                    dispatch({
                        type: GET_MINI_CART_SUCCESS,
                        payload: {
                            type: GET_MINI_CART_SUCCESS,
                            data: response?.data.cart,
                        },
                    });
                }
            }
        } catch (error: any) {
            dispatch({
                type: GET_MINI_CART_FAILED,
                message: error?.message,
            });
        }
    };
};

export const createEmptyCartAction = () => {
    return async (dispatch: any) => {
        try {
            const cartId = getSessionItem(CART_ID);
            if (!cartId) {
                const { data: response } = await createEmptCart();
                const { customerCart: cartResponse } = response.data;
                if (cartResponse) {
                    dispatch({
                        type: CREATE_EMPTY_CART_SUCCESS,
                        payload: { type: CREATE_EMPTY_CART_SUCCESS },
                    });
                    const token = getSessionItem(CART_ID);
                    if (token) {
                        try {
                            await mergerCart({
                                sourceCartId: token,
                                destinationCartId: cartResponse.id,
                            });
                        } catch (error) {
                            showToast(ERROR, "Unable to merge cart");
                        }
                    }
                    if (cartResponse && cartResponse.id) {
                        setSessionItem(CART_ID, cartResponse.id);
                    }
                } else if (response.data.createEmptyCart) {
                    dispatch({
                        type: CREATE_EMPTY_CART_SUCCESS,
                        payload: { type: CREATE_EMPTY_CART_SUCCESS },
                    });
                    if (response && response.data.createEmptyCart) {
                        setSessionItem(CART_ID, response.data.createEmptyCart);
                    }
                } else {
                    handleCartError(response, dispatch);
                    showToast(ERROR, response.errors[0].message);
                }
            } else {
                dispatch(getCartCountAction());
                dispatch(getCartAction());
            }
        } catch (error) {
            dispatch({
                type: CREATE_EMPTY_CART_ERROR,
                payload: { type: CREATE_EMPTY_CART_ERROR, message: error },
            });
            showToast(ERROR, error);
        }
    };
};

export const getWishlist = (payload: any) => {
    return async (dispatch: any) => {
        try {
            const { data: response } = await getWishlistItems(payload);
            if (response) {
                if (response.errors) {
                    dispatch({
                        type: GET_WISHLIST_ERROR,
                        payload: {
                            type: GET_WISHLIST_ERROR,
                            message: response.errors[0].message,
                        },
                    });
                    showToast(ERROR, response.errors[0].message);
                } else {
                    dispatch({
                        type: GET_WISHLIST_SUCCESS,
                        payload: {
                            type: GET_WISHLIST_SUCCESS,
                            data: response.data.customer?.wishlists[0],
                        },
                    });
                }
            }
        } catch (error) {
            dispatch({
                type: GET_WISHLIST_ERROR,
                payload: error,
            });
            showToast(ERROR, error);
        }
    };
};

export const removeFromWishlistItems = (payload: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: ADD_TO_WISHLIST_REQUEST,
                payload: {
                    type: ADD_TO_WISHLIST_REQUEST,
                    data: null,
                },
            });
            const { data: response } = await removeFromWishlist(payload);
            if (response.errors) {
                dispatch({
                    type: DELETE_WISHLIST_ERROR,
                    payload: {
                        type: DELETE_WISHLIST_ERROR,
                        message: response.errors[0].message,
                    },
                });
                showToast(ERROR, response.errors[0].message);
            } else {
                dispatch({
                    type: GET_WISHLIST_SUCCESS,
                    payload: {
                        type: GET_WISHLIST_SUCCESS,
                        data: {
                            items_v2: {
                                items: response.data.removeProductsFromWishlist.wishlist.items,
                            },
                            items_count:
                                response.data.removeProductsFromWishlist.wishlist.items_count,
                        },
                    },
                });
                showToast(
                    SUCCESS,
                    "Product has been removed from wishlist successfully."
                );
            }
        } catch (error) {
            dispatch({
                type: DELETE_WISHLIST_ERROR,
                payload: {
                    type: DELETE_WISHLIST_ERROR,
                    message: error,
                },
            });
            showToast(ERROR, error);
        }
    };
};

export const MoveProductToWishlist = (payload: any) => {
    return async (dispatch: any) => {
        try {
            payload.cartId = getSessionItem(CART_ID);
            const response = await addProductToWishlist(payload);
            if (response && response.length > 0) {
                const { data: firstResponse } = response[0];
                const { data: secondResponse } = response[1];
                const { data: fR } = firstResponse;
                const { data: sR } = secondResponse;
                if (
                    (fR &&
                        fR.addProductsToWishlist &&
                        (fR.addProductsToWishlist.errors ||
                            fR.addProductsToWishlist.user_errors.length > 0)) ||
                    (sR &&
                        sR.removeCoupanFromCart &&
                        (sR.removeItemFromCart.errors ||
                            sR.removeItemFromCart.user_errors.length > 0))
                ) {
                    let message = "";
                    if (sR.removeItemFromCart.errors) {
                        message = fR.addProductsToWishlist.errors
                            ? fR.addProductsToWishlist.errors[0].message
                            : sR.removeItemFromCart.errors
                                ? sR.removeItemFromCart.errors[0].message
                                : "Something went wrong";
                        // REMOVE FROM WISHLIST
                        const wishlistId =
                            fR.addProductsToWishlist.addProductToWishlist.wishlist.id;
                        const wishlistItemId =
                            fR.addProductsToWishlist.addProductToWishlist.wishlist.items_v2
                                .items[0].id;
                        await removeFromWishlist({ wishlistId, wishlistItemId });
                    } else if (sR.removeItemFromCart.user_errors.length > 0) {
                        message = fR.addProductsToWishlist.errors
                            ? firstResponse.user_errors[0].message
                            : sR.removeItemFromCart.user_errors
                                ? sR.removeItemFromCart.user_errors[0].message
                                : "Something went wrong";
                        const wishlistId =
                            fR.addProductsToWishlist.addProductToWishlist.wishlist.id;
                        const wishlistItemId =
                            fR.addProductsToWishlist.addProductToWishlist.wishlist.items_v2
                                .items[0].id;
                        await removeFromWishlist({ wishlistId, wishlistItemId });
                    }
                    showToast(ERROR, message);
                    dispatch({
                        type: ADD_TO_WISHLIST_ERROR,
                        payload: { type: ADD_TO_WISHLIST_ERROR, message: message },
                    });
                } else {
                    showToast(SUCCESS, "Moved to wishlist successfully");
                    dispatch(getCartCountAction());
                    dispatch({
                        type: GET_MINI_CART_SUCCESS,
                        payload: {
                            type: GET_MINI_CART_SUCCESS,
                            data: sR.removeItemFromCart.cart,
                        },
                    });
                    dispatch({
                        type: GET_CART_PAGE_SUCCESS,
                        payload: {
                            type: GET_CART_PAGE_SUCCESS,
                            data: sR.removeItemFromCart.cart,
                        },
                    });
                }
            }
        } catch (error) {
            showToast(ERROR, error);
            dispatch({
                type: ADD_TO_WISHLIST_ERROR,
                payload: { type: ADD_TO_WISHLIST_ERROR, message: error },
            });
        }
    };
};


export const addToWishlist = (payload: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: ADD_TO_WISHLIST_REQUEST,
                payload: {
                    type: ADD_TO_WISHLIST_REQUEST,
                    data: null,
                },
            });
            const { data: response } = await addProductToWishlistItem(payload);
            if (response.errors || response.user_errors) {
                const error =
                    response.errors[0].message || response.user_errors[0].message;
                showToast(ERROR, error);
            } else {
                showToast(SUCCESS, "The product added to wishlist successfully");
                dispatch({
                    type: ADD_TO_WISHLIST_SUCCESS,
                    payload: {
                        type: ADD_TO_WISHLIST_SUCCESS,
                        data: response.data?.addProductsToWishlist?.wishlist,
                    },
                });
            }
        } catch (error) {
            showToast(ERROR, error);
        }
    };
};
