import {
  ERROR,
  KEY_USER_TOKEN,
  SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_SUCCESS,
} from "../../Constants/Labels";
import { CART_ID } from "../../Constants/LocalStorage";
import { mergerCart } from "../../Services/graphQL.service";
import {
  login,
  register,
  getUserDetail as getUser,
  changePassword,
  changeEmail,
  logout,
} from "../../Services/User.service";
import {
  clearSession,
  getSessionItem,
  setSessionItem,
  showToast,
} from "../../Utility/Utilities";
import { createEmptyCartAction } from "../Pages/CartPage/CartPageActions2";
import {
  CHANGE_ACCOUNT_FAILED,
  CHANGE_ACCOUNT_REQUEST,
  CHANGE_ACCOUNT_SUCCESS,
  GET_USER_DETAIL_FAILED,
  GET_USER_DETAIL_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_FAILED,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from "./AuthType";

export function onRegistration(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
        payload: { type: REGISTER_REQUEST },
      });
      const { data: response } = await register(payload);
      const { createCustomerV2: graphQLResponse } = response.data;
      if (graphQLResponse) {
        showToast(SUCCESS, USER_REGISTER_SUCCESS);
        dispatch(loginAction(payload));
        dispatch({
          type: REGISTER_SUCCESS,
          payload: {
            type: REGISTER_SUCCESS,
            data: graphQLResponse?.customer,
          },
        });
      }
      if (response.errors && response.errors[0]) {
        dispatch({
          type: REGISTER_FAILED,
          payload: {
            type: REGISTER_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error: any) {
      console.error("SOMETHING WENT WRONG WHILE REGISTERING ", error);
      showToast(ERROR, USER_REGISTER_FAIL);
      dispatch({
        type: REGISTER_FAILED,
        payload: { type: REGISTER_FAILED, message: error?.message },
      });
    }
  };
}

export function loginAction(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: LOGIN_REQUEST,
        payload: { type: LOGIN_REQUEST },
      });
      const { data: response } = await login(payload);
      const data = response?.data?.generateCustomerToken?.token;
      if (data) {
        setSessionItem(KEY_USER_TOKEN, data);
        const sourceCartId = getSessionItem(CART_ID);
        if (sourceCartId) {
          const { data: cartResponse } = await mergerCart({ sourceCartId });
          setSessionItem(CART_ID, cartResponse?.data?.mergeCarts?.id);
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            type: LOGIN_SUCCESS,
            data,
          },
        });
      } else if (response && response.errors[0] && response.errors[0].message) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: LOGIN_FAILED,
          payload: {
            type: LOGIN_FAILED,
            message: response.errors[0].message,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, "Something went wrong");
      dispatch({
        type: LOGIN_FAILED,
        payload: { type: LOGIN_FAILED, message: error?.message },
      });
    }
  };
}

export function changePasswordAction(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CHANGE_ACCOUNT_REQUEST,
        payload: { type: CHANGE_ACCOUNT_REQUEST },
      });
      const { data: response } = await changePassword(payload);
      const data = response?.data?.changeCustomerPassword?.email;
      if (data) {
        showToast(SUCCESS, "Password updated successfully");
        dispatch({
          type: CHANGE_ACCOUNT_SUCCESS,
          payload: {
            type: CHANGE_ACCOUNT_SUCCESS,
            data,
          },
        });
      } else {
        showToast(ERROR, response.errors[0]?.message);
        dispatch({
          type: CHANGE_ACCOUNT_FAILED,
          payload: {
            type: CHANGE_ACCOUNT_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, JSON.stringify(error));
      console.error("SOMETHING WENT WRONG WHILE LOGIN ", error);
      dispatch({
        type: CHANGE_ACCOUNT_FAILED,
        payload: { type: CHANGE_ACCOUNT_FAILED, message: error?.message },
      });
    }
  };
}

export function changeEmailAction(payload: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CHANGE_ACCOUNT_REQUEST,
        payload: { type: CHANGE_ACCOUNT_REQUEST },
      });
      const { data: response } = await changeEmail(payload);
      const data = response?.data?.updateCustomer?.customer;
      if (data) {
        showToast(SUCCESS, "Email updated successfully.");
        dispatch({
          type: CHANGE_ACCOUNT_SUCCESS,
          payload: {
            type: CHANGE_ACCOUNT_SUCCESS,
            data,
          },
        });
      } else if (response && response.errors) {
        showToast(ERROR, response.errors[0]?.message);
        dispatch({
          type: CHANGE_ACCOUNT_FAILED,
          payload: {
            type: CHANGE_ACCOUNT_FAILED,
            message: response.errors[0]?.message,
          },
        });
      }
    } catch (error: any) {
      showToast(ERROR, JSON.stringify(error));
      console.error("SOMETHING WENT WRONG WHILE LOGIN ", error);
      dispatch({
        type: CHANGE_ACCOUNT_FAILED,
        payload: { type: CHANGE_ACCOUNT_FAILED, message: error?.message },
      });
    }
  };
}

export function getUserDetail(isFromHomePage: boolean) {
  return async (dispatch: any) => {
    try {
      const { data: response } = await getUser(isFromHomePage);
      const data = response?.data?.customer;
      dispatch({
        type: GET_USER_DETAIL_SUCCESS,
        payload: {
          type: GET_USER_DETAIL_SUCCESS,
          data,
        },
      });
    } catch (error: any) {
      console.error("SOMETHING WENT WRONG WHILE FETCHING USER DETAIL ", error);
      showToast(ERROR, JSON.stringify(error));
      dispatch({
        type: GET_USER_DETAIL_FAILED,
        payload: { type: GET_USER_DETAIL_FAILED, message: error?.message },
      });
    }
  };
}

export function logoutAction() {
  return async (dispatch: any) => {
    try {
      await logout();
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: {
          type: LOGOUT_SUCCESS,
        },
      });
      clearSession();
      dispatch(createEmptyCartAction());
    } catch (error: any) {
      showToast(ERROR, JSON.stringify(error));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: null,
      });
      clearSession();
    }
  };
}
