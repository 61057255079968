import { getCartCountPayload, getCatePayload, getMiniCartPayload, createPaypalExpressTokenPayload, onPaypalExpressPlaceOrderPayload, onPlaceOrderPayload, filterProductPayload, getRelatedProductHTMLContentPayload, getRapydCheckoutIdPayload, rapydPlaceOrderPayload, getVariantsPricePayload, stripePlaceOrderPayload, generateOrderSplashKeyPayload, verifySplashKeyPayload, getPaymentInstallmentsPayload, getPaymnetMinAmountPayload, orderSplashKeyCallbackPayload } from './../Constants/Api';
import axios, { AxiosPromise, AxiosResponse } from "axios";
import {
  END_POINTS,
  getCMSPagePayload,
  getHomepageDataPayload,
  getUrlResolverPayload,
  getProductListDataPayload,
  submitContactUsFormPayload,
  createEmptyCartPayload,
  getCartDetailPayload,
  updateCartItemsPayload,
  getCMSBlockPayload,
  deleteCartPayload,
  addProductToWishlistPayload,
  removeFromWishlistPayload,
  applyCoupanPayload,
  removeCoupanPayload,
  getStoreConfigPayload,
  getWishlistPayload,
  getOrderSuccessPayload,
  getCategoryListPayload,
  getAddressesPayload,
  getCountryNamePayload,
  deleteAddressPayload,
  updateAddressPayload,
  createAddressPayload,
  updateGuestUserPayload,
  searchCategoryPayload,
  getProductDetailPayload,
  mergeCartPayload,
  addProductToCartPayload,
  addSimpleProductToCartPayload,
  getBlogPageListPayload,
  setShippingAddressPayload,
  getDeliveryMethodPayload,
  setDeliveryMethodPayload,
  getBrainTreeTokenPayload,
  getCustomFormDetailPayload,
  placeOrderPayload,
  setBillingAddressPayload,
  isEmailAvailablePayload,
  setGuestEmailPayload,
  getBlogPageCategoryListPayload,
  getBlogRecentPostListPayload,
  submitSampleFormPayload,
  getBlogDetailsPagePayload,
  getAuthorDetailByIdPayload as getAuthorUrlKeyPayload,
  getBlogCategoriesUrlPayload,
  searchBlogPostPayload,
  getMetaTitlePayload,
  getHomepageMetaPayload,
  getUpsellProductsPayload,
  getConfigurableOptionsPayload,
} from "../Constants/Api";

import { IGraphQLResponse } from "../Interfaces/Response.interface";

export const _REQUEST_GRAPHQL = (page: string): any => {
  return {
    url: END_POINTS.graphQL,
    method: "post",
    headers: Headers,
    data: {
      query: page,
      variables: {},
    },
  };
};


export const getGraphQLDataByPage = (
  page: string
): AxiosPromise<IGraphQLResponse> => {
  return axios(_REQUEST_GRAPHQL(getHomepageDataPayload(page)));
};

export const getCategoryNameByUrl = (url: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getUrlResolverPayload(url)));
};

export const getCMSPage = (url: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCMSPagePayload(url)));
};

export const getCMSBlock = (id: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCMSBlockPayload(id)));
};

export const getProductList = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getProductListDataPayload(payload)));
};

export const getCateList = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCatePayload(payload)));
};

export const submitContactUs = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(submitContactUsFormPayload(payload)));
};

export const storeConfig = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getStoreConfigPayload()));
};

export const createEmptCart = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(createEmptyCartPayload()));
};

export const addToCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(addProductToCartPayload(payload)));
};

export const mergerCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(mergeCartPayload(payload)));
};

export const addSimpleProductToCartItem = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(addSimpleProductToCartPayload(payload)));
};

export const getCartDetail = (cartId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCartDetailPayload(cartId)));
};

export const getMiniCartDetail = (cartId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getMiniCartPayload(cartId)));
};

export const getCartCountDetail = (cartId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCartCountPayload(cartId)));
};

export const updateCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(updateCartItemsPayload(payload)));
};

export const deleteCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(deleteCartPayload(payload)));
};

export const addProductToWishlist = (
  payload: any
): Promise<[AxiosResponse<any, any>, AxiosResponse<any, any>]> => {
  return Promise.all([
    axios(_REQUEST_GRAPHQL(addProductToWishlistPayload(payload))),
    axios(_REQUEST_GRAPHQL(deleteCartPayload(payload))),
  ]);
};

export const addProductToWishlistItem = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(addProductToWishlistPayload(payload)));
};

export const getMetaTitle = (url: string, type: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getMetaTitlePayload(url, type)));
};

export const getHomepageMetaTitle = (url: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getHomepageMetaPayload(url)));
};

export const removeFromWishlist = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(removeFromWishlistPayload(payload)));
};

export const getWishlistItems = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getWishlistPayload(payload)));
};

export const addCoupan = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(applyCoupanPayload(payload)));
};

export const removeCoupan = (cartId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(removeCoupanPayload(cartId)));
};

export const getOrdersuccess = (orderId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getOrderSuccessPayload(orderId)));
};

export const getCategoryList = (uId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCategoryListPayload(uId)));
};

export const getUserAddresses = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getAddressesPayload()));
};

export const getCountryName = (country_code: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCountryNamePayload(country_code)));
};

export const deleteCustomerAddress = (addressId: number): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(deleteAddressPayload(addressId)));
};

export const updateCustomerAddress = (address: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(updateAddressPayload(address)));
};

export const createCustomerAddress = (address: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(createAddressPayload(address)));
};

export const guestNewsletter = (emailId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(updateGuestUserPayload(emailId)));
};

export const searchCategory = ({
  category,
  page,
  size,
}: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(searchCategoryPayload(category, page, size)));
};

export const getProductDetail = (name: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getProductDetailPayload(name)));
};

export const getUpsellProductDetail = (name: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getUpsellProductsPayload(name)));
};

export const getConfigurableOptions = (name: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getConfigurableOptionsPayload(name)));
};

export const getBlogListItems = ({
  type,
  page,
  categoryId,
}: any): AxiosPromise<any> => {
  return axios(
    _REQUEST_GRAPHQL(getBlogPageListPayload(type, page, categoryId))
  );
};

export const getBlogCategoryItems = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getBlogPageCategoryListPayload()));
};

export const getBlogDetails = (nameKey: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getBlogDetailsPagePayload(nameKey)));
};

export const getBlogRecentItems = (id: number): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getBlogRecentPostListPayload(id)));
};

export const setCheckoutAddress = (type: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(setShippingAddressPayload(type)));
};

export const getDeliveryMethods = (cid: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getDeliveryMethodPayload(cid)));
};

export const setCheckoutDelMethod = (type: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(setDeliveryMethodPayload(type)));
};

export const getBrainTreeToken = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getBrainTreeTokenPayload()));
};
export const getCustomFormDetail = (form_name: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getCustomFormDetailPayload(form_name)));
};

export const placeOrder = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(placeOrderPayload(payload)));
};

export const setBillingAddressOnCart = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(setBillingAddressPayload(payload)));
};

export const checkCustomerEmail = (email: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(isEmailAvailablePayload(email)));
};

export const setGuestEmailAddress = (
  email: string,
  cartId: string
): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(setGuestEmailPayload(email, cartId)));
};

export const submitFreeSampleForm = (payload: any, payload2: any, firstname: string, lastname: string, email: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(submitSampleFormPayload(payload, payload2, firstname, lastname, email)));
};

export const getAuthUrlKey = (id: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getAuthorUrlKeyPayload(id)));
};

export const getCategoryUrlKey = (id: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getBlogCategoriesUrlPayload(id)));
};

export const searchBlogPost = (searchKey: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(searchBlogPostPayload(searchKey)));
};


export const createPaypalExpressToken = (cartId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(createPaypalExpressTokenPayload(cartId)));
};

export const onPaypalExpressPlaceOrder = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(onPaypalExpressPlaceOrderPayload(payload)));
}

export const onPlaceOrderService = (payload: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(onPlaceOrderPayload(payload)));
}

export const getRapydCheckoutId = (cartId: any): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getRapydCheckoutIdPayload(cartId)));
}

export const rapydPlaceOrder = (cartId: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(rapydPlaceOrderPayload(cartId)));
}

export const stripePlaceOrder = (cartId: string , paymentMethodId : string) : AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(stripePlaceOrderPayload(cartId , paymentMethodId)))
}

export const filterProduct = (uIds: string, sku: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(filterProductPayload(uIds, sku)));
}

export const getRelatedProductHTMLContent = (sku: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getRelatedProductHTMLContentPayload(sku)));
}

export const getVariantsPrice = (sku: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getVariantsPricePayload(sku)))
}

export const generateOrderSplashKey = (orderId : string) : AxiosPromise<any>  => {
  return axios(_REQUEST_GRAPHQL(generateOrderSplashKeyPayload(orderId)))
}


export const getPaymentInstallment = (orderTotal : string) : AxiosPromise<any>  => {
  return axios(_REQUEST_GRAPHQL(getPaymentInstallmentsPayload(orderTotal)))
}


export const getPaymentMinAmount = (): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(getPaymnetMinAmountPayload()))
}

export const orderSplashKeyCallback = (quote_entity_id: string): AxiosPromise<any> => {
  return axios(_REQUEST_GRAPHQL(orderSplashKeyCallbackPayload(quote_entity_id)))
}