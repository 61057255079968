/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, useEffect } from "react";
import { getSessionItem, isAuth } from "./Utility/Utilities";
import { KEY_USER_TOKEN } from "./Constants/Labels";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_SUCCESS } from "./Redux/Auth/AuthType";
import "./App.scss";
import AOS from "aos";
import {
  createEmptyCartAction,
  getCartCountAction,
} from "./Redux/Pages/CartPage/CartPageActions2";
import { CREATE_EMPTY_CART_SUCCESS } from "./Redux/Pages/CartPage/CartPageTypes";
import { getUserDetail } from "./Redux/Auth/AuthAction";
import ReactCookieFirst from "react-cookiefirst";
import RouterConfig from "./Routesconfig";

AOS.init({
  offset: 150,
  duration: 500,
  easing: "ease-in-out",
  delay: 10,
  once: true,
});

function App() {
  const token = getSessionItem(KEY_USER_TOKEN);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth()) {
      dispatch(getUserDetail(true));
    }
    if(window.location.pathname !== "/dividebuy/payment/response"){
      dispatch(createEmptyCartAction());
    }
  }, []);

  const createCartResponse = useSelector(
    (state: any) => state.createCartResponse
  );

  useEffect(() => {
    if (
      createCartResponse &&
      createCartResponse.type === CREATE_EMPTY_CART_SUCCESS
    ) {
      dispatch(getCartCountAction());
    }
  }, [createCartResponse]);

  useEffect(() => {
    if (token) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          type: LOGIN_SUCCESS,
          data: token,
        },
      });
    }
  }, [token]);

  return (
    <>
      <RouterConfig />
      <ReactCookieFirst apiKey={process.env.REACT_APP_COOKIEFIRST_API_KEY} />
    </>
  );
}

export default App;
