import Skeleton from "react-loading-skeleton";

const CardBannerSkl = (props: { count: number; keyStr: string }) => {
  return (
    <div className="custom-container" key={props.keyStr}>
      <div className="cardrel-content">
        {Array.from(Array(2)).map((count, index) => (
          <div className="inner-card skl-content" key={index}>
            <div className="cr-image">
              <Skeleton />
            </div>
            <div className="desc-skl">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardBannerSkl;
