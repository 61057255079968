import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  BLOG_CATEGORY_PAGE,
  BLOG_DETAIL,
  BLOG_LISTING,
  BLOG_SEARCH_CATEGORY,
  CART_PAGE,
  CHECKOUT,
  CONTACT_US,
  DIVIDEBUY_PROGRESS,
  CUSTOMER_SHOWCASE,
  DYNAMIC_ROUTE,
  DYNAMIC_ROUTE2,
  FORGOT_PWD,
  HOME,
  LOGIN,
  MY_ADDRESS,
  MY_DETAIL,
  MY_ORDERS_PAGE,
  MY_WISHLIST,
  NEWSLETTER,
  ORDER_DETAILS,
  ORDER_SUCCESS,
  PAYMENT_PROGRESS,
  PAYPAL_RETURN_URL,
  RAPYD_PROGRESS,
  RESET_PWD,
  SEARCH_CATEGORY,
  SIGN_UP,
  SOMETHING_WRONG,
  STRIPE_PROGRESS,
} from "./Constants/Route";

const AllowCookiesPopup = lazy(
  () => import("./Components/AllowCookiesPopup/AllowCookiesPopup")
);

const ForgotPassword = lazy(
  () => import("./Components/Authentication/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("./Components/Authentication/ResetPassword")
);
const Login = lazy(() => import("./Components/Authentication/Login"));
const Signup = lazy(() => import("./Components/Authentication/Signup"));
const Footer = lazy(() => import("./Components/Footer/Footer"));
const Headers = lazy(() => import("./Components/Headers/Headers"));
const Home = lazy(() => import("./Components/Home/Home"));
const Main = lazy(() => import("./Main"));
const CartPage = lazy(() => import("./Components/CartPage/CartPage"));
const MyAddress = lazy(
  () => import("./Components/MyAccount/MyAddress/MyAddress")
);
const MyDetails = lazy(
  () => import("./Components/MyAccount/MyDetails/MyDetails")
);
const MyOrders = lazy(() => import("./Components/MyAccount/MyOrders/MyOrders"));
const Newsletter = lazy(
  () => import("./Components/MyAccount/Newsletter/Newsletter")
);
const OrderDetail = lazy(
  () => import("./Components/MyAccount/OrderDetail/OrderDetail")
);
const Wishlist = lazy(() => import("./Components/MyAccount/Wishlist/Wishlist"));
const BlogPage = lazy(() => import("./Components/Blogs/BlogPage"));
const ContactUs = lazy(() => import("./Components/CMSPages/ContactUs"));
const ProtectedRoute = lazy(() => import("./ProtectedRoutes"));
const OrderSuccess = lazy(
  () => import("./Components/Checkout/OrderSuccess/OrderSuccess")
);
const Checkout = lazy(() => import("./Components/Checkout/Checkout"));
const SearchCategory = lazy(
  () => import("./Components/SearchCategory/SearchCategory")
);
const BlogDetails = lazy(() => import("./Components/Blogs/BlogDetails"));
const PaypalSuccess = lazy(() => import("./Components/PaypalSuccess"));
const SomethingWrongPage = lazy(
  () => import("./Components/ErrorPages/SomethingWentWrongPage")
);
const ScrollToTop = lazy(() => import("./Utility/ScrollTop"));
const PaymentProgress = lazy(
  () => import("./Components/Checkout/PaymentProgress")
);

const StripePaymentProgress = lazy(
  () => import("./Components/Checkout/StripeCheckout/StripePaymentProgress")
);

const PageNotFound = lazy(
  () => import('./Components/ErrorPages/PageNotFound')
);

const DivideBuyProgress = lazy(
  () => import("./Components/Checkout/DivideBuy/DivideBuyProgress/DivideBuyProgress")
);

const CustomerShowCase = lazy(
  () => import("./Components/CustomerShowCase/CustomerShowCase")
);


const RouterConfig = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Headers />
      <ToastContainer />
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={CUSTOMER_SHOWCASE} element={<CustomerShowCase />} />
        <Route path={SOMETHING_WRONG} element={<SomethingWrongPage />} />
        <Route path={DYNAMIC_ROUTE} element={<Main />} />
        <Route path={DYNAMIC_ROUTE2} element={<Main />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={SIGN_UP} element={<Signup />} />
        <Route path={FORGOT_PWD} element={<ForgotPassword />} />
        <Route path={RESET_PWD} element={<ResetPassword />} />
        <Route path={CART_PAGE} element={<CartPage />} />
        {/* Protected routes */}
        <Route
          path={MY_ORDERS_PAGE}
          element={
            <ProtectedRoute>
              <MyOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_WISHLIST}
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_DETAIL}
          element={
            <ProtectedRoute>
              <MyDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={MY_ADDRESS}
          element={
            <ProtectedRoute>
              <MyAddress />
            </ProtectedRoute>
          }
        />
        <Route
          path={NEWSLETTER}
          element={
            <ProtectedRoute>
              <Newsletter />
            </ProtectedRoute>
          }
        />
        <Route
          path={ORDER_DETAILS + ":orderId"}
          element={
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          }
        />
        <Route path={BLOG_LISTING} element={<BlogPage />} />
        <Route path={BLOG_CATEGORY_PAGE} element={<BlogPage />} />
        <Route path={BLOG_SEARCH_CATEGORY} element={<BlogPage />} />
        <Route path={BLOG_DETAIL} element={<BlogDetails />} />
        <Route path={CONTACT_US} element={<ContactUs />} />
        <Route path={ORDER_SUCCESS} element={<OrderSuccess />} />
        <Route path={CHECKOUT} element={<Checkout />} />
        <Route path={SEARCH_CATEGORY} element={<SearchCategory />} />
        <Route path={PAYPAL_RETURN_URL} element={<PaypalSuccess />} />
        <Route path={PAYMENT_PROGRESS} element={<PaymentProgress />} />
        <Route path={RAPYD_PROGRESS} element={<PaymentProgress />} />
        <Route path={DIVIDEBUY_PROGRESS} element={<DivideBuyProgress />} />
        
        <Route path={STRIPE_PROGRESS} element={<StripePaymentProgress />} />
        <Route path="*"  element={<PageNotFound />} />
      </Routes>
      <Footer />
      {/* <AllowCookiesPopup /> */}
    </BrowserRouter>
  );
};

export default RouterConfig;
