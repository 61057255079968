import { isAuth } from "../Utility/Utilities";

export const MAX_CALLS = 3;
export const END_POINTS = {
  login: process.env.REACT_APP_API_URL + "/login",
  graphQL: process.env.REACT_APP_API_URL + "/graphql",
};

// Queries
export const getHomepageDataPayload = (page: string): string => {
  return `{
  component(path: "${page}") {
    message
    status
    statusCode
    component {
      componentIdentifier
      data{
        title
        elementId
        description
        image
        mimage
        redirectType
        redirectTypeId
        urlKey
        action{
          title
          type
        }
        details{
          price
          customerName
          specialPrice
          component_url
        }
      }
    }
  }
}`;
};

export const getUrlResolverPayload = (url: string): string => {
  return `{
  urlResolver(url: "${url}") {
    entity_uid
    relative_url
    redirectCode
    type
    sku
  }
}`;
};

export const registerPayload = (payload: any): string => {
  return `mutation {
  createCustomerV2(
    input: {
      firstname: "${payload.firstName}",
      lastname: "${payload.lastName}",
      email: "${payload.email}",
      password: "${payload.password}",
      is_subscribed: ${payload.isSubscribed !== undefined ? payload.isSubscribed : false
    }
    }
  ) ${getCustomerPayload(true)}
}`;
};

export const loginPayload = (payload: any): string => {
  return `mutation {
  generateCustomerToken(
    email: "${payload.email}",
    password: "${payload.password}"
  ) {
    token
  }
}`;
};

export const getLogoutPayload = (): string => {
  return `mutation {
  revokeCustomerToken {
    result
  }
}`;
};

export const getAddressesPayload = (): string => {
  return `{
  customer { email addresses { ${addressType}} } }`;
};

export const mergeCartPayload = ({ sourceCartId }: any): string => {
  return `mutation {
    mergeCarts(
        source_cart_id: "${sourceCartId}"
    ) {
        id
    }
  }`;
};

export const getCountryNamePayload = (countryId: string): string => {
  return `{
  country(id: "${countryId}") {
    full_name_locale
  }
}`;
};

export const getChangePasswordPayload = (payload: any): string => {
  return `mutation {
  changeCustomerPassword(
    currentPassword: "${payload.password}",
    newPassword: "${payload.cpassword}"
  ) {
    id
    email
  }
}`;
};

export const getChangeEmailPayload = (payload: any): string => {
  return `mutation {
  updateCustomer(input : ${getUserRequest(payload)}) ${getCustomerPayload(
    false
  )}
}`;
};

export const updateLoggedInNewsLetterPayload = (
  isSubscribed: boolean,
  firstname: string
): string => {
  return `mutation {
  updateCustomerV2(
    input: {
      firstname: "${firstname}",
      is_subscribed: ${isSubscribed}
    }
  ) {
    customer {
      is_subscribed
    }
  }
}
`;
};

export const updateGuestUserPayload = (email: string): string => {
  return `mutation {
  subscribeEmailToNewsletter(
    email: "${email}"
  ) {
    status
  }
}`;
};

export const forgotPasswordPayload = (payload: { email: string }): string => {
  return `mutation {
  requestPasswordResetEmail(
    email: "${payload.email}"
  )
}`;
};

export const resetPasswordPayload = (payload: any): string => {
  return `mutation {
  resetPassword(
    email: "${payload.email}",
    resetPasswordToken: "${payload.token}",
    newPassword: "${payload.password}"
  )
}`;
};

export const getCMSPagePayload = (payload: string): string => {
  return `{
  cmsPage(identifier: "${payload}") {
    identifier
    url_key
    title
    content
    content_heading
    page_layout
    meta_title
    meta_description
    meta_keywords
  }
}`;
};

export const getCatePayload = ({ entity_uid }: any) => {
  return `{
    categoryList(filters:{category_uid:{
      eq: "${entity_uid}"
    }}) {
    category_content
    cms_block_custom {
      content
      identifier
    }
   description
    image
    name
    mobile_thumbnail
    display_mode
  }
  }`;
};

export const getProductListDataPayload = ({ page, size, entity_uid }: any) => {
  return `{
  products(filter: {
    category_uid: {
      eq: "${entity_uid}"
    }
  }, pageSize: ${size}, currentPage: ${page}, sort: {
    position: ASC
  }) {
    total_count
    items {
      productbadgetext
      showproductbadge
      name
      __typename
      url_key
      url_suffix
      sku
      small_image {
        label
				url(width: 424, height: 312)
      }
      price_range {
        minimum_price {
          regular_price {
            value
          }
          final_price {
            value
          }
        }
        maximum_price {
          final_price {
            value
          }
        }
      }
    }
    page_info {
      current_page
      page_size
      total_pages
    }
    total_count
  }
}`;
};

export const submitContactUsFormPayload = (payload: any): string => {
  return `mutation {
    contactusFormSubmit(input: {
      email:"${payload.email}",
      fullname: "${payload.fullName}",
      telephone: "${payload.telephone}",
      message: "${payload.message}"
    }) {
      success_message
    }
  }`;
};

export const getStoreConfigPayload = (): string => {
  return ` 
  {
    storeConfig {
      header_logo_src
      store_code
      store_name
      is_default_store
      store_group_code
      base_currency_code
      base_media_url
      base_url
      root_category_uid
    }
    currency {
        base_currency_symbol
    }
  }
  `;
};

export const createEmptyCartPayload = (): string => {
  if (isAuth()) {
    return `{
  customerCart{
    id
  }
}`;
  }
  return `mutation {
  createEmptyCart
}`;
};

export const getCartDetailPayload = (cartId: string): string => {
  return `{
   cart(cart_id: "${cartId}") {
    id
    ${cartType}
  }
}`;
};

export const getMiniCartPayload = (cartId: string): string => {
  return `{
  cart(cart_id: "${cartId}") {
    prices {
      grand_total{value}
    }
    items {
      uid
      quantity
      ... on ConfigurableCartItem {
        configurable_options {
          value_label
        }
      }
      prices {
        row_total {
          value
        }
      }
      product {
        name
        sku
        url_key
        url_suffix
        availability_message
        image {
          label
          url
        }
      }
    }
  }
}
`;
};

export const getCartCountPayload = (cartId: string): string => {
  return `{
   cart(cart_id: "${cartId}") {
    total_quantity
  }
}`;
};

export const addProductToCartPayload = (payload: any): string => {
  return `
  mutation {
    addConfigurableProductsToCart(
      input: { cart_id: "${payload.cartId}", cart_items: [{
        parent_sku:"${payload.parent_sku}",
        data: {
          quantity: 1
          sku: "${payload.child_sku}"
        }
      }] }
    ) {
      cart {
        id
        ${cartType}
      }
    }
  }
  `;
};

export const addSimpleProductToCartPayload = (payload: any): string => {
  return `mutation {
    addSimpleProductsToCart (input: {
      cart_id: "${payload.cartId}",
      cart_items: {
        data: {
          sku: "${payload.sku}",
          quantity: 1
        }
      }
    }) {
      cart {
       id
       ${cartType}
      }
    }
  }`;
};

export const updateCartItemsPayload = ({ cartId, uid, quantity }: any) => {
  return `
mutation {
  updateCartItems(input: { cart_id: "${cartId}", cart_items: [
    {
      cart_item_uid: "${uid}",
      quantity: ${quantity}
    }
  ] }) {
    cart {
      id
      ${cartType}
    }
  }
}
`;
};

export const getCMSBlockPayload = (payload: string): string => {
  return `{
  cmsBlocks(identifiers: [${payload}]) {
    items {
      identifier
      title
      content
    }
  }
}`;
};

export const deleteCartPayload = ({ cartId, cartItemId }: any): string => {
  return `
mutation {
  removeItemFromCart(input: { cart_id: "${cartId}" , cart_item_uid: "${cartItemId}" }) {
    cart {
      id
      ${cartType}
    }
  }
}
`;
};

export const addProductToWishlistPayload = ({ sku, quantity }: any): string => {
  return `mutation {
  addProductsToWishlist(
    wishlistId: "0"
    wishlistItems: [{ sku: "${sku}"  quantity: ${quantity} }]
  ) {
    user_errors {
      code
      message
    }
    wishlist {
      id
      items_v2{
        items{
          id
          product{
            name
            sku
          }
        }
      }
    }
  }
}
`;
};

export const removeFromWishlistPayload = ({ wishlistItemId }: any): string => {
  return `
mutation {
  removeProductsFromWishlist(wishlistId: "0", wishlistItemsIds: ["${wishlistItemId}"]) {
    user_errors {
      code
      message
    }
    ${wishlistType}
  }
}
`;
};

export const removeCoupanPayload = (cartId: string): string => {
  return `
  mutation {
  removeCouponFromCart(input: 
    { cart_id: "${cartId}" }) {
    cart {
      id
      ${cartType}
    }
  }
}`;
};

export const applyCoupanPayload = (payload: any): string => {
  return `mutation {
  applyCouponToCart(
    input: {
      cart_id: "${payload.cartId}"
      coupon_code: "${payload.coupan_code}"
    }
  ) {
    cart {
      id
      ${cartType}
    }
  }
}`;
};

export const getOrderSuccessPayload = (orderId: string): string => {
  return `{
    OrderSuccess(id: "${orderId}") {
      description
      image
      number
      title
    }
  }`;
};

export const getCategoryListPayload = (uid: string): string => {
  return `
    {
      categories(
        filters: {
          parent_id: {in: ["${uid}"]}
        }
      ) {
        total_count
        items {
          uid
          level
          name
          path
          include_in_menu
          children_count
          url_key
          url_path
          url_suffix
          cms_block {
            content
            title
            identifier
          }
          children {
            uid
            level
            name
            path
            include_in_menu
            children_count
            url_key
            url_path
            children {
              uid
              level
              name
              path
            }
          }
        }
        page_info {
          current_page
          page_size
          total_pages
        }
      }
  }`;
};

export const getMetaTitlePayload = (url: string, type: string): string => {
  const t =
    type !== ""
      ? ` seoData(url: "${url.slice(1)}", type: "${type}") {`
      : ` seoData(url: "${url.slice(1)}", type: "") {`;
  return `{
    ${t}
    meta_title
  }
}`;
};

export const getHomepageMetaPayload = (url: string): string => {
  return `{
    storeConfig {
        canonical_url
        meta_title
        meta_keywords
        meta_description
    }
}
`;
};

export const getWishlistPayload = ({ size, page }: any): string => {
  return `{
    customer {
      wishlists {
        id
        items_count
        ${size && page
      ? `items_v2(pageSize: ${size}, currentPage: ${page})`
      : "items_v2"
    } {
          items {
            id
            product {
              sku
              name
              url_key
              url_suffix
              image {
                label
                url
              }
              price_range {
                maximum_price {
                  regular_price {
                    value
                  }
                  final_price {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
}`;
};

export const deleteAddressPayload = (addressId: number): string => {
  return `
  mutation {
  deleteCustomerAddress(id: ${addressId}) 
}
  `;
};

export const updateAddressPayload = (address: any): string => {
  return `mutation {
  updateCustomerAddress(id: ${address.id}, ${addressFormType(address)}) {
    id
    ${addressType}
  }
}`;
};

export const searchCategoryPayload = (
  searchCat: string,
  page: number,
  size: number
): string => {
  return `{
  products(search: "${searchCat}", pageSize: ${size}, currentPage: ${page}) {
    total_count
    items {
      productbadgetext
      showproductbadge
      uid
      name
      small_image {
        url(width:424, height:312)
      }
      image {
        url
        label
      }
      thumbnail {
        url
        label
      }
      url_key
      url_suffix
      price_range {
        minimum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
        maximum_price {
          final_price {
            value
            currency
          }
        }
      }
    }
    page_info {
      current_page
      page_size
      total_pages
    }
  }
}`;
};

export const getUpsellProductsPayload = (sku: string): string => {
  return `{
  products(filter: { sku: { eq: "${sku}" } }) {
      items {
        upsell_products {
          name
          small_image {
            url(width: 424, height: 312)
            label
          }
          url_key
          url_suffix
          price_range {
            minimum_price {
              regular_price {
                value
              }
              final_price {
                value
              }
            }
          }
        }
      }
    }
  }`;
};

export const getConfigurableOptionsPayload = (sku: string): string => {
  return `{
  products(filter: { sku: { eq: "${sku}" } }) {
    items {
      ... on ConfigurableProduct {
        configurable_options {
          uid
          label
          position
          attribute_code
          values {
            uid
            value_index
            label
            product_status
            swatch_data {
              value
              __typename
              ... on ImageSwatchData {
                thumbnail
                value
                group
                color_group
              }
              ... on ColorSwatchData {
                value
                group
                color_group
              }
            }
          }
        }
        variants {
          product {
            uid
            stock_status
            availability_message
            price_range {
              minimum_price {
                regular_price {
                  value
                }
                final_price {
                  value
                }
              }
            }
            name
            sku
            cms_block
            enable_free_fabric_sample
            image {
              label
              disabled
              position
              url(width: 776, height: 576)
            }
            media_gallery {
              position
              url(width: 776, height: 576)
              disabled
              label
            }
            media_gallery {
              position
              url(width: 776, height: 576)
              disabled
              label
            }
          }
          attributes {
            uid
            label
            code
            value_index
          }
        }
      }
    }
  }
}
`;
};

export const filterProductPayload = (uIds: string, sku: string): string => {
  return `{
  products(filter: { sku: { eq: "${sku}" } }) {
    items {
      ... on ConfigurableProduct {
        configurable_product_options_selection(configurableOptionValueUids: [${uIds}]) {
          variant {
            uid
            sku
            stock_status
            availability_message
            name
            cms_block
            enable_free_fabric_sample
            image {
              label
              disabled
              position
              url(width: 776, height: 576)
            }
            price_range {
              minimum_price {
                regular_price {
                  value
                }
                final_price {
                  value
                }
              }
            }
          }
          media_gallery {
            url(width: 776, height: 576)
            label
            position
          }
        }
      }
    }
  }
}`;
};

export const getRelatedProductHTMLContentPayload = (sku: string): string => {
  return `{
  products(filter: { sku: { eq: "${sku}" } }) {
    items {
        related_products {
        name
        description {
          html
        }
        image {
          label
          disabled
          position
          url
        }
  }}}}`;
};

export const getProductDetailPayload = (sku: string): string => {
  return `{
  products(filter: { sku: { eq: "${sku}" } }) {
    items {
      productbannermobiletop
      dividebuy_enable
      stock_status
      productbanner
      enable_free_fabric_sample
      __typename
      related_products {
        name
        sku
        price_range {
          minimum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
          }
        }
      }
      uid
      name
      sku
      short_description {
        html
      }
      media_gallery {
        label
        disabled
        position
        url(width: 776, height: 576)
        ...on ProductVideo {
          video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
          }
        }
      }
      image {
        url(width: 776, height: 576)
        disabled
        position
        label
      }
      cms_block
      description {
        html
      }
      dimensions {
        html
      }
      whyus {
        html
      }
      delivery {
        html
      }
      name
      sku
      availability_message
      price_range {
        minimum_price {
          regular_price {
            value
          }
          final_price {
            value
          }
        }
      }
    }
  }
}
`;
};

export const getMyOrderPayload = ({ size, page }: any): string => {
  return `{
    customer {
      orders(pageSize: ${size}, currentPage: ${page}) {
        total_count
        items {
          
          shipping_address{
            street
            firstname
            lastname
            city
            company
            country_code
            fax
            region
            telephone
          }
          items{
            quantity_ordered
            id
            product_name
            product_url_key
            product_sku
            selected_options{
              label
              value
            }
            product_sale_price{
              currency
              value
            }
            status
          }
          id
          increment_id
          order_date
          status
          total {
            taxes{
              amount{
                currency
                value
              }
              rate
              title
            }
            grand_total {
              value
              currency
            }
          }
        }
      }
    }
  }`;
};

export const getMyOrderDetailsPayload = (orderId: string): string => {
  return `{
    customer {
      orders(filter:{number: {eq:"${orderId}"}}) {
        total_count
        items {
          shipping_address{
            street
            firstname
            lastname
            city
            company
            country_code
            fax
            region
            telephone
          }
          items{
            quantity_ordered
            id
            product_url_key
            product_name
            product_sku
            selected_options{
              label
              value
            }
            product_sale_price{
              currency
              value
            }
            status
          }
          id
          increment_id
          order_date
          status
          shipping_method
          payment_methods {
            name
          }
          total {
            subtotal {
              value
            }
            taxes{
              amount{
                currency
                value
              }
              rate
              title
            }
            discounts{
              amount{
                currency
                value
              }
              label
            }
            shipping_handling{
              total_amount{
                currency
                value
              }
              amount_excluding_tax{
                currency
                value
              }
              amount_including_tax{
                currency
                value
              }
              discounts{
                amount{
                  currency
                  value
                }
              }
              taxes{
                amount{
                  currency
                  value
                }
                rate
                title
              }
              
            }
            grand_total {
              value
              currency
            }
          }
        }
      }
    }
  }`;
};

export const getProductThumbImagePayload = (url: string): string => {
  return `{
    products(filter: { 
        url_key: {
          in: [${url}]
        }
    }) {
      items {
        url_key
        thumbnail {
          label
          url
        }
      }
    }
  }`;
};

export const getBlogPageListPayload = (
  type: string,
  page: number,
  categoryId?: number
): string => {
  const query =
    type === "ALL"
      ? `type: ALL, page: ${page}`
      : `type: CATEGORY, page: ${page}, entityId: ${categoryId}`;
  return `{
    amBlogPosts(${query}) {
      all_post_size
      items {
        ${blogPayload}  
      }
    }
  }`;
};

export const getBlogPageCategoryListPayload = (): string => {
  return `{
    amBlogCategories {
      items {
        category_id
        created_at
        name
        parent_id
        post_count
        status
        store_id
        updated_at
        url_key
      }
    }
  }`;
};

export const getBlogRecentPostListPayload = (id: number): string => {
  return `{
    amBlogRecentPostsWidget(id: ${id}) {
      items {
        title
        url_key
      }
    }
  }`;
};

export const getBlogDetailsPagePayload = (key: string): string => {
  return `{
    amBlogPost(urlKey: "${key}") {
      author_id
      author_name
      categories
      created_at
      full_content
      is_featured
      list_thumbnail
      list_thumbnail_alt
      post_id
      post_thumbnail
      published_at
      short_content
      status
      title
      updated_at
      url_key
    }
  }`;
};

export const setShippingAddressPayload = (payload: any): string => {
  return `mutation {
  setShippingAddressesOnCart(
    input: {
      cart_id: "${payload.cartId}"
      shipping_addresses: [{
        ${shipping_addresses(payload)}
      }]
    }
  )
    {
      cart {
      id
      ${cartType}
      }
    }
  }`;
};

const shipping_addresses = (payload: any): string => {
  return payload.customerAddressId
    ? `customer_address_id: ${payload.customerAddressId} `
    : `address: {
        firstname: "${payload.firstname}"
        lastname: "${payload.lastname}"
        street: "${payload.street}"
        city: "${payload.city}"
        ${getField(
      "company",
      payload.company ? '"' + payload.company + '"' : null
    )},
        region: "${payload?.region?.region}"
        postcode: "${payload.postcode}"
        country_code: "GB"
        telephone: "${payload.telephone}"
        save_in_address_book: true
      }`;
};

export const setBillingAddressPayload = (payload: any): string => {
  const billing_address = payload.address
    ? `billing_address: {
          address: {
                firstname: "${payload.address.firstname}"
                lastname: "${payload.address.lastname}"
                ${payload.address.company
      ? 'company: "' + payload.address.company + '"'
      : ""
    }
                street: ["${payload.address.street}"]
                city: "${payload.address.city}"
                region: "${payload.address.state}"
                postcode: "${payload.address.postcode}"
                country_code: "GB"
                telephone: "${payload.address.telephone}"
                save_in_address_book: true
              }
          }`
    : `billing_address: {
    customer_address_id: ${payload.customerAddressId}  }`;
  return `
mutation {
  setBillingAddressOnCart(input: { 
    ${billing_address}
    cart_id: "${payload.cartId}" }) {
    cart {
      id
      ${cartType}
    }
  }
}`;
};

export const getDeliveryMethodPayload = (cart_id: any): string => {
  return `{
    cart(cart_id: "${cart_id}") {
      id
      ${cartType}
    }
  }`;
};

export const setDeliveryMethodPayload = (payload: any): string => {
  return `mutation {
    setShippingMethodsOnCart(input: {
      cart_id: "${payload.cartId}"
      shipping_methods: [
        {
          carrier_code: "${payload.carrier_code}",
          method_code: "${payload.method_code}"
        }
      ]
    }) {
      cart {
        id
        ${cartType}
      }
    }
  }`;
};

export const getCustomFormDetailPayload = (form_name: string): string => {
  return `{
    CustomForm(code: "${form_name}"){
      code
      form_json
      form_title
      title
    }
  }`;
};

export const getBrainTreeTokenPayload = (): string => {
  return `mutation {
    createBraintreeClientToken
  }`;
};

export const placeOrderPayload = (payload: any): string => {
  return `mutation {
    setPaymentMethodOnCart(input: { 
      cart_id: "${payload.cartId}"
      payment_method: {code: "${payload.method_code}"
      ${payload.method_code === "braintree"
      ? `braintree: {
            payment_method_nonce: "${payload.nonce?.nonce}"
            is_active_payment_token_enabler: false
          }}`
      : "}"
    }
    }) {
      cart {
        id
        ${cartType}
    }}
}`;
};

export const rapydPlaceOrderPayload = (cartId: string): string => {
  return `mutation {
      setPaymentMethodOnCart (input: {
    cart_id: "${cartId}"
    payment_method: {
      code: "rapyd_card"
    }
  }) {
    cart {
      id
    }
  }
  
    placeOrder(input: {cart_id: "${cartId}"}) {
      order {
        order_number
      }
    }
  }`;
};

export const stripePlaceOrderPayload = (cartId: string, paymentMethodId: string): any => {
  return `
  mutation {
    setPaymentMethodOnCart(input: {
        cart_id: "${cartId}"
        payment_method: {
          code: "stripe_payments"
          stripe_payments: {
            payment_element: true
            payment_method: "${paymentMethodId}"
            save_payment_method: true
            manual_authentication: "card"
          }
        }
    }) {
      cart {
        id
        ${cartType}
      }
    }
  }
  `
}

export const onPlaceOrderPayload = (payload: any): any => {
  return `mutation {
    placeOrder(input: {cart_id: "${payload.cart_id}"}) {
      order {
        order_id
        order_number
        client_secret
      }
    }
  }`;
};

export const isEmailAvailablePayload = (email: string): string => {
  return `{
  isEmailAvailable(email: "${email}") {
    is_email_available
  }
}`;
};

export const setGuestEmailPayload = (email: string, cartId: string): string => {
  return `mutation {
  setGuestEmailOnCart(input: {
    cart_id: "${cartId}"
    email: "${email}"
  }) {
    cart {
      email
    }
  }
}`;
};

export const submitSampleFormPayload = (
  payload: any,
  payload2: any,
  firstname: string,
  lastname: string,
  email: string
): string => {
  return `mutation{customFormSubmit(input:{
    first_name: "${firstname}",
    last_name: "${lastname}",
    email: "${email}",
    customformuid: "free_sample_request"
    formjson: "${payload}",
    customerFormjson: "${payload2}"
  }){success_message}}`;
};

export const getAuthorDetailByIdPayload = (id: string): string => {
  return `{
  amBlogAuthor(id: ${id}) {
    author_id
    url_key
  }
}`;
};

export const getBlogCategoriesUrlPayload = (id: string): string => {
  return `{
  amBlogCategory(id: ${id}) {
    category_id
    name
    url_key
  }
}`;
};

export const searchBlogPostPayload = (searchKey: string): string => {
  return `{
    amBlogPostsSearch(page: 1, query: "${searchKey}") {
    all_post_size
    items {
      ${blogPayload}
    }
  }
}`;
};

export const createPaypalExpressTokenPayload = (cartId: string): string => {
  return `mutation {
    createPaypalExpressToken(
      input: {
        cart_id: "${cartId}"
        code: "paypal_express"
        express_button: true
        urls: {
          return_url: "checkout/onepage/success.html"
          cancel_url: "checkout"
        }
      }
    ) {
      token
      paypal_urls {
        start
        edit
      }
    }
  }`;
};

export const onPaypalExpressPlaceOrderPayload = (payload: any): string => {
  return `mutation {
    setPaymentMethodOnCart(
      input: {
        cart_id: "${payload.cart_id}"
        payment_method: {
          code: "paypal_express"
          paypal_express: {
            payer_id: "${payload.payer_id}"
            token: "${payload.token}"
        }
      }
      }
    ) {
      cart {
         id
        ${cartType}
        selected_payment_method {
          code
        }
      }
    }
  }
  `;
};

export const getRapydCheckoutIdPayload = (cartId: string): string => {
  return `{
  rapydToken(cart_id: "${cartId}") {
    token
  }
}`;
};

export const getPaymnetMinAmountPayload = (): string => {
  return `{
    paymentminimumamount (storeid:"1") {
       paymentminimumamount
    }
}`;
};

export const getVariantsPricePayload = (sku: string): string => {
  return `{
  products(filter: { sku: { eq: "${sku}" } }) {
    items {
      ... on ConfigurableProduct {
        variants {
          product {
            uid
            availability_message
            price_range {
              minimum_price {
                regular_price {
                  value
                }
                final_price {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
};

// Constants

export const blogPayload = `
      author_name
      author_id
      categories
      created_at
      full_content
      is_featured
      list_thumbnail
      list_thumbnail_alt
      post_id
      post_thumbnail
      published_at
      short_content
      status
      title
      updated_at
      url_key`;

export const getCustomerPayload = (isFromHomePage: boolean): string => {
  return isFromHomePage
    ? `{
    customer {
      firstname
      lastname
    }
  }`
    : `{
    customer {
      firstname
      lastname
      email
      is_subscribed
    }
  }`;
};

export const generateOrderSplashKeyPayload = (orderId: string) => {
  return `{
  ordersplashkey(order_id: "${orderId}") {
    splashkey
  }
}`
}

export const orderSplashKeyCallbackPayload = (quote_entity_id: string) => {
  return `{
    ordersplashkeycallback(splashkeycallback:${quote_entity_id}") {
       status
      cartid
    }
}`
}

export const verifySplashKeyPayload = (splashKey: string) => {
  return `{
    verifyKey(splash_key: "${splashKey}") {
    order_id
    order_number
    message
    status
  }
}`
}

export const getPaymentInstallmentsPayload = (orderTotal : string) => {
  return `
    {
      getpaymentInstallments(order_total:${orderTotal}){
         installmentcontent
      }
    }

    `
}

const cartType = `
 available_payment_methods {
  code
  title
}
billing_address {
  street
  firstname
  lastname
  city
  postcode
  region {
    code
    label
  }
  telephone
  company
}
shipping_addresses {
  street,
      firstname,
      lastname,
      city,
      postcode,
      region{
        code
        label
      },
      telephone
      company
      available_shipping_methods {
        carrier_code
          method_code
          method_title
        carrier_title
        amount {value}
      }
      selected_shipping_method {
        carrier_code
          method_code
          method_title
        carrier_title
        amount {value}
      }
    }
items{
      uid
      quantity
       __typename
      ... on ConfigurableCartItem{
        configurable_options{
          configurable_product_option_uid
          configurable_product_option_value_uid
          option_label
          value_label
        }
        configured_variant{
          dividebuy_enable
        }
      }
      prices{
        discounts{
          amount{
            currency
            value
          }
          label
        }
        fixed_product_taxes{
          amount{
            currency
            value
          }
          label
        }
        price{
          currency
          value
        }
        row_total{
          currency
          value
        }
        row_total_including_tax{
          currency
          value
        }
        total_item_discount{
          currency
          value
        }
      }
      product{
        name
        sku
        url_key
        dividebuy_enable
        color
        url_suffix
        availability_message
        image {
            label
            url
        }
      }
    }
    applied_coupons {
      code
    }
    prices {
      discounts {
        label
        amount {
          value
        }
      }
      applied_taxes {
        label
        amount {
          value
        }
      }
      subtotal_excluding_tax {
        value
      }
      grand_total {
        value
      }
    }`;

const wishlistType = `wishlist {
    items_count
    items {
      id
      product {
        sku
        name
        url_key
        url_suffix
        image {
          label
          url
        }
        price_range {
          minimum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
          }
        }
      }
    }
  }`;

const getField = (key: string, value: any) => {
  return value ? `${key}: ${value}` : "";
};

export const createAddressPayload = (address: any): string => {
  return `mutation {
  createCustomerAddress(input: {
    ${getField("city", address.city ? '"' + address.city + '"' : null)},
    ${getField(
    "region",
    address.state ? `{ region: "${address.state}"}` : undefined
  )},
    ${getField(
    "firstname",
    address.firstname ? '"' + address.firstname + '"' : null
  )},
    ${getField(
    "lastname",
    address.lastname ? '"' + address.lastname + '"' : null
  )},
    ${getField(
    "company",
    address.company ? '"' + address.company + '"' : null
  )},
    ${getField(
    "telephone",
    address.telephone ? '"' + address.telephone + '"' : null
  )},
    ${getField("street", address.street ? '"' + address.street + '"' : null)},
    ${getField(
    "postcode",
    address.postcode ? '"' + address.postcode + '"' : null
  )},
    country_id: GB,
    default_shipping: true,
    default_billing: true,
  }) {
    id
    ${addressType}
  }}`;
};

const addressFormType = (address: any) => {
  return `input: {
    firstname: "${address.firstname}",
    lastname: "${address.lastname}",
    company: "${address.company}",
    street: "${address.street}",
    city: "${address.city}",
    ${address.state
      ? `region: {
      region: "${address.state}"
    },`
      : ""
    }
    telephone: "${address.telephone}",
    postcode: "${address.postcode}"
    default_shipping: true,
    default_billing: true,
  }`;
};

const getUserRequest = (payload: any): string => {
  return payload.password && payload.email
    ? `{
    firstname: "${payload.firstname}"
    lastname: "${payload.lastname}",
    email: "${payload.email}"
    password: "${payload.password}"
  }`
    : `{
    firstname: "${payload.firstname}"
    lastname: "${payload.lastname}"
  }`;
};

const addressType = `
      id
      firstname
      lastname
      street
      city
      company
      region {
        region_code
        region
      }
      postcode
      country_code
      telephone`;
