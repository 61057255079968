import Skeleton from "react-loading-skeleton";

const CategoryBlockSkl = (props: { count: number; keyStr: string }) => {
  return (
    <div className="custom-container" key={props.keyStr}>
      <div className="category-list">
        {Array.from(Array(props.count)).map((data, index) => (
          <div className="item" key={index}>
            <div className="skl-img-section">
              <Skeleton />
            </div>
            <p className="ts cat-name">
              <Skeleton />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryBlockSkl;
