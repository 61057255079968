import { pushDataLayer } from "../../../Utility/gtmhelper";
import { ERROR, SUCCESS } from "../../../Constants/Labels";
import { CART_ID } from "../../../Constants/LocalStorage";
import {
  addCoupan,
  addSimpleProductToCartItem,
  addToCart,
  createEmptCart,
  deleteCart,
  getCartDetail,
  removeCoupan,
  updateCart,
} from "../../../Services/graphQL.service";
import {
  getSessionItem,
  removeSessionItem,
  setSessionItem,
  showToast,
} from "../../../Utility/Utilities";
import {
  ADD_COUPAN_FAILED,
  ADD_PRODUCT_TO_CART_FAILED,
  ADD_PRODUCT_TO_CART_SUCCESS,
  DELETE_CART_ERROR,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  GET_CART_PAGE_ERROR,
  GET_CART_PAGE_SUCCESS,
  GET_MINI_CART_SUCCESS,
  REMOVE_COUPAN_FAILED,
  UPDATE_CART_ERROR,
  UPDATE_CART_REQUEST,
} from "./CartPageTypes";
import { getCartCountAction } from "./CartPageActions2";

export const getCartAction = () => {
  return async (dispatch: any) => {
    // dispatch({ type: GET_CART_PAGE_REQUEST });
    try {
      const cartIdStorage = getSessionItem(CART_ID);
      if (cartIdStorage) {
        const { data: response } = await getCartDetail(cartIdStorage);
        const { data: cartDetail } = response;
        if (cartDetail && cartDetail.cart) {
          let errorMsg = response.errors ? response.errors[0]?.message === 'Some item options or their combination are not currently available.' : false
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: cartDetail.cart,
              error: errorMsg
            },
          });
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: cartDetail.cart,
              error: errorMsg
            },
          });
        } else {
          if (
            response.errors[0].message ===
            `Some item options or their combination are not currently available.` ||
            response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
          ) {
            dispatch({
              type: GET_CART_PAGE_SUCCESS,
              payload: {
                type: GET_CART_PAGE_SUCCESS,
                data: cartDetail.cart,
                error: true
              },
            });
            dispatch({
              type: GET_MINI_CART_SUCCESS,
              payload: {
                type: GET_MINI_CART_SUCCESS,
                data: cartDetail.cart,
              },
            });
          } else {
            handleCartError(response, dispatch);
          }
        }
      }
    } catch (error: any) {
      dispatch({
        type: GET_CART_PAGE_ERROR,
        message: error.message,
      });
    }
  };
};

export const updateCartItem = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({ type: UPDATE_CART_REQUEST });
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (payload.cartId) {
        const { data: response } = await updateCart(payload);
        const { data: cartDetail } = response;
        if (
          cartDetail &&
          cartDetail.updateCartItems &&
          cartDetail.updateCartItems.cart
        ) {
          dispatch(getCartCountAction());
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: cartDetail.updateCartItems.cart,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: cartDetail.updateCartItems.cart,
            },
          });
        } else {
          if (
            response.errors[0].message ===
            `Some item options or their combination are not currently available.` ||
            response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
          ) {
            handleCartError(response, dispatch);
            dispatch(getCartCountAction());
            dispatch({
              type: GET_MINI_CART_SUCCESS,
              payload: {
                type: GET_MINI_CART_SUCCESS,
                data: cartDetail.updateCartItems?.cart,
                error: true
              },
            });
            dispatch({
              type: GET_CART_PAGE_SUCCESS,
              payload: {
                type: GET_CART_PAGE_SUCCESS,
                data: cartDetail.updateCartItems?.cart,
                error: true
              },
            });
          } else {
            handleCartError(response, dispatch);
            dispatch({
              type: GET_CART_PAGE_ERROR,
              payload: {
                type: GET_CART_PAGE_ERROR,
                message: response.errors[0].message,
                data: payload,
              },
            });
          }
        }
      } else {
        showToast(ERROR, "Something went wrong");
        dispatch({
          type: GET_CART_PAGE_ERROR,
          payload: {
            type: GET_CART_PAGE_ERROR,
            data: payload,
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: UPDATE_CART_ERROR,
        payload: { type: UPDATE_CART_REQUEST, message: error?.message },
      });
    }
  };
};

export const deleteCartItem = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({ type: DELETE_CART_REQUEST });
    try {
      payload.cartId = getSessionItem(CART_ID);
      const { data: response } = await deleteCart(payload);
      if (response.errors) {
        if (
          response.errors[0].message ===
          `Some item options or their combination are not currently available.` ||
          response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
        ) {
          dispatch({
            type: DELETE_CART_SUCCESS,
            payload: { type: DELETE_CART_SUCCESS, data: payload.cartItemId },
          });
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: response?.data?.removeItemFromCart?.cart,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.removeItemFromCart?.cart,
            },
          });
          dispatch(getCartCountAction());
        } else {
          handleCartError(response, dispatch);
          dispatch({
            type: DELETE_CART_ERROR,
            payload: {
              type: DELETE_CART_ERROR,
              data: response.errors[0].message,
            },
          });
        }
      } else {
        dispatch({
          type: DELETE_CART_SUCCESS,
          payload: { type: DELETE_CART_SUCCESS, data: payload.cartItemId },
        });
        dispatch({
          type: GET_MINI_CART_SUCCESS,
          payload: {
            type: GET_MINI_CART_SUCCESS,
            data: response?.data?.removeItemFromCart?.cart,
          },
        });
        dispatch({
          type: GET_CART_PAGE_SUCCESS,
          payload: {
            type: GET_CART_PAGE_SUCCESS,
            data: response?.data?.removeItemFromCart?.cart,
          },
        });
        dispatch(getCartCountAction());
      }
    } catch (error: any) {
      dispatch({
        type: DELETE_CART_ERROR,
        payload: {
          type: DELETE_CART_ERROR,
          data: error.message,
        },
      });
      showToast(ERROR, error.message);
    }
  };
};


export const removeCoupanFromCart = () => {
  return async (dispatch: any) => {
    try {
      const cartId = getSessionItem(CART_ID);
      const { data: response } = await removeCoupan(cartId);
      if (response.errors) {
        if (
          response.errors[0].message ===
          `Some item options or their combination are not currently available.` ||
          response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
        ) {
          showToast(SUCCESS, "Removed coupon from basket successfully");
          if (response.data) {
            dispatch({
              type: GET_MINI_CART_SUCCESS,
              payload: {
                type: GET_MINI_CART_SUCCESS,
                data: response?.data?.removeCouponFromCart?.cart,
              },
            });
            dispatch({
              type: GET_CART_PAGE_SUCCESS,
              payload: {
                type: GET_CART_PAGE_SUCCESS,
                data: response?.data?.removeCouponFromCart?.cart,
              },
            });
          }
        } else {
          showToast(ERROR, response.errors[0].message);
          dispatch({
            type: REMOVE_COUPAN_FAILED,
            payload: {
              type: REMOVE_COUPAN_FAILED,
              message: response.errors[0].message,
            },
          });
        }
      } else {
        showToast(SUCCESS, "Removed coupon from basket successfully");
        if (response.data) {
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data: response?.data?.removeCouponFromCart?.cart,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.removeCouponFromCart?.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: REMOVE_COUPAN_FAILED,
        payload: { type: REMOVE_COUPAN_FAILED, message: error },
      });
    }
  };
};

export const applyCoupanToCart = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      const { data: response } = await addCoupan(payload);
      if (response.errors) {
        if (
          response.errors[0].message ===
          `Some item options or their combination are not currently available.` ||
          response.errors[0].message === `Can't check requested quantity for products without Source Items support.`
        ) {
          showToast(SUCCESS, "The coupon applied to basket successfully");
          if (response.data) {
            dispatch({
              type: GET_CART_PAGE_SUCCESS,
              payload: {
                type: GET_CART_PAGE_SUCCESS,
                data: response?.data?.applyCouponToCart?.cart,
              },
            });
          }
        } else {
          showToast(ERROR, response.errors[0].message);
          dispatch({
            type: ADD_COUPAN_FAILED,
            payload: {
              type: ADD_COUPAN_FAILED,
              message: response.errors[0].message,
            },
          });
        }
      } else {
        showToast(SUCCESS, "The coupon applied to basket successfully");
        if (response.data) {
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data: response?.data?.applyCouponToCart?.cart,
            },
          });
        }
      }
    } catch (error) {
      showToast(ERROR, error);
      dispatch({
        type: ADD_COUPAN_FAILED,
        payload: { type: ADD_COUPAN_FAILED, message: error },
      });
    }
  };
};

export const addProductToCartItem = (payload: any) => {
  return async (dispatch: any) => {
    try {
      payload.cartId = getSessionItem(CART_ID);
      if (!payload.cartId) {
        const { data: response } = await createEmptCart();
        const { customerCart: cartResponse } = response.data;
        if (cartResponse) {
          if (cartResponse && cartResponse.id) {
            payload.cartId = cartResponse.id;
            setSessionItem(CART_ID, cartResponse.id);
          }
        } else if (response.data.createEmptyCart) {
          if (response && response.data.createEmptyCart) {
            payload.cartId = response.data.createEmptyCart;
            setSessionItem(CART_ID, response.data.createEmptyCart);
          }
        } else {
          showToast(ERROR, response.errors[0].message);
        }
      }
      let data = null;
      let response = null;
      if (payload.parent_sku && payload.child_sku) {
        const { data: res } = await addToCart(payload);
        response = res;
        if (res && !res.errors) {
          data = response?.data?.addConfigurableProductsToCart?.cart;
        } else if (res.errors) {
          dispatch({
            type: ADD_PRODUCT_TO_CART_FAILED,
            payload: {
              type: ADD_PRODUCT_TO_CART_FAILED,
            },
          });
        }
      }
      if (payload.simple_sku) {
        const { data: res } = await addSimpleProductToCartItem({
          ...payload,
          sku: payload.simple_sku,
        });
        response = res;
        if (res && !res.errors) {
          data = response?.data?.addSimpleProductsToCart?.cart;
        } else if (res.errors) {
          dispatch({
            type: ADD_PRODUCT_TO_CART_FAILED,
            payload: {
              type: ADD_PRODUCT_TO_CART_FAILED,
            },
          });
        }
      }
      if (payload.sku && payload.sku.length > 0) {
        for (let index = 0; index < payload.sku.length; index++) {
          const sku = payload.sku[index];
          const { data: res } = await addSimpleProductToCartItem({ ...payload, sku });
          response = res;
          if (res && !res.errors) {
            data = response?.data?.addSimpleProductsToCart?.cart;
          } else if (res.errors) {
            dispatch({
              type: ADD_PRODUCT_TO_CART_FAILED,
              payload: {
                type: ADD_PRODUCT_TO_CART_FAILED,
              },
            });
          }
        }
      }
      if (response.errors) {
        showToast(ERROR, response.errors[0].message);
        dispatch({
          type: ADD_PRODUCT_TO_CART_FAILED,
          payload: {
            type: ADD_PRODUCT_TO_CART_FAILED,
          },
        });
      } else {
        showToast(SUCCESS, "Product added to basket successfully");
        // dispatch(getCartCountAction())
        dispatch({
          type: ADD_PRODUCT_TO_CART_SUCCESS,
          payload: {
            type: ADD_PRODUCT_TO_CART_SUCCESS,
          },
        });
        if (response.data) {
          pushDataLayer("addToCart", {
            click: {
              name: data.items[0].product.name,
              sku: data.items[0].product.sku,
              price: "£ " + data.items[0].prices.price.value,
              quantity: data.items[0].quantity,
            },
          });
          dispatch(getCartCountAction());
          dispatch({
            type: GET_MINI_CART_SUCCESS,
            payload: {
              type: GET_MINI_CART_SUCCESS,
              data,
            },
          });
          dispatch({
            type: GET_CART_PAGE_SUCCESS,
            payload: {
              type: GET_CART_PAGE_SUCCESS,
              data,
            },
          });
        }
      }
    } catch (error: any) {
      showToast(ERROR, error?.message);
      dispatch({
        type: ADD_PRODUCT_TO_CART_FAILED,
        payload: {
          type: ADD_PRODUCT_TO_CART_FAILED,
        },
      });
    }
  };
};

export function handleCartError(response: any, dispatch: any) {
  if (response.errors[0].message === `The cart isn't active.`) {
    removeSessionItem(CART_ID);
    dispatch(createEmptCart());
  } else {
    if (
      response.errors[0].message.indexOf("Could not find a cart with ID") ===
      -1 &&
      response.errors[0].message.indexOf(
        `Can't assign cart to store in different website.`
      ) === -1
    ) {
      showToast(ERROR, response.errors[0].message);
      dispatch({
        type: GET_CART_PAGE_ERROR,
        payload: {
          type: GET_CART_PAGE_ERROR,
          message: response.errors[0].message,
        },
      });
    } else {
      removeSessionItem(CART_ID);
      dispatch(createEmptCart());
    }
  }
}
