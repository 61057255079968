import { isAuth } from "../Utility/Utilities";

export const createEmptyCartPayload = (): string => {
  if (isAuth()) {
    return `{
  customerCart{
    id
  }
}`;
  }
  return `mutation {
  createEmptyCart
}`;
};

export const getCartDetailPayload = (cartId: string): string => {
  return `{
   cart(cart_id: "${cartId}") {
    id
    ${cartType}
  }
}`;
};

export const getMiniCartPayload = (cartId: string): string => {
  return `{
  cart(cart_id: "${cartId}") {
    prices {
      grand_total{value}
    }
    items {
      uid
      quantity
      ... on ConfigurableCartItem {
        configurable_options {
          value_label
        }
      }
      prices {
        row_total {
          value
        }
      }
      product {
        name
        sku
        url_key
        url_suffix
        availability_message
        image {
          label
          url
        }
      }
    }
  }
}
`;
};

export const getCartCountPayload = (cartId: string): string => {
  return `{
   cart(cart_id: "${cartId}") {
    total_quantity
  }
}`;
};

export const addProductToCartPayload = (payload: any): string => {
  return `
  mutation {
    addConfigurableProductsToCart(
      input: { cart_id: "${payload.cartId}", cart_items: [{
        parent_sku:"${payload.parent_sku}",
        data: {
          quantity: 1
          sku: "${payload.child_sku}"
        }
      }] }
    ) {
      cart {
        id
        ${cartType}
      }
    }
  }
  `;
};

export const addSimpleProductToCartPayload = (payload: any): string => {
  return `mutation {
    addSimpleProductsToCart (input: {
      cart_id: "${payload.cartId}",
      cart_items: {
        data: {
          sku: "${payload.sku}",
          quantity: 1
        }
      }
    }) {
      cart {
       id
       ${cartType}
      }
    }
  }`;
};

export const updateCartItemsPayload = ({ cartId, uid, quantity }: any) => {
  return `
mutation {
  updateCartItems(input: { cart_id: "${cartId}", cart_items: [
    {
      cart_item_uid: "${uid}",
      quantity: ${quantity}
    }
  ] }) {
    cart {
      id
      ${cartType}
    }
  }
}
`;
};


export const cartType = `
 available_payment_methods {
  code
  title
}
billing_address {
  street
  firstname
  lastname
  city
  postcode
  region {
    code
    label
  }
  telephone
  company
}
shipping_addresses {
  street,
      firstname,
      lastname,
      city,
      postcode,
      region{
        code
        label
      },
      telephone
      company
      available_shipping_methods {
        carrier_code
          method_code
          method_title
        carrier_title
        amount {value}
      }
      selected_shipping_method {
        carrier_code
          method_code
          method_title
        carrier_title
        amount {value}
      }
    }
items{
      uid
      quantity
       __typename
      ... on ConfigurableCartItem{
        configurable_options{
          configurable_product_option_uid
          configurable_product_option_value_uid
          option_label
          value_label
        }
      }
      prices{
        discounts{
          amount{
            currency
            value
          }
          label
        }
        fixed_product_taxes{
          amount{
            currency
            value
          }
          label
        }
        price{
          currency
          value
        }
        row_total{
          currency
          value
        }
        row_total_including_tax{
          currency
          value
        }
        total_item_discount{
          currency
          value
        }
      }
      product{
        name
        sku
        url_key
        color
        url_suffix
        availability_message
        image {
            label
            url
        }
      }
    }
    applied_coupons {
      code
    }
    prices {
      discounts {
        label
        amount {
          value
        }
      }
      applied_taxes {
        label
        amount {
          value
        }
      }
      subtotal_excluding_tax {
        value
      }
      grand_total {
        value
      }
    }`;

export const getCategoryListType = ` items {
          uid
          level
          name
          path
          include_in_menu
          children_count
          url_key
          url_path
          url_suffix
          cms_block {
            content
            title
            identifier
          }
          children {
            uid
            level
            name
            path
            children_count
            url_key
            url_path
            children {
              uid
              level
              name
              path
            }
          }
        }
        page_info {
          current_page
          page_size
          total_pages
        }`

export const searchQueryType = `items {
      uid
      name
      small_image {
        url(width:424, height:312)
      }
      image {
        url
        label
      }
      thumbnail {
        url
        label
      }
      url_key
      url_suffix
      price_range {
        minimum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
        maximum_price {
          final_price {
            value
            currency
          }
        }
      }
    }
    page_info {
      current_page
      page_size
      total_pages
    }`

export const configurablePayloadType = `items {
      ... on ConfigurableProduct {
        configurable_options {
          uid
          label
          position
          attribute_code
          values {
            uid
            value_index
            label
            product_status
            swatch_data {
              value
              __typename
              ... on ImageSwatchData {
                thumbnail
                value
                group
                color_group
              }
              ... on ColorSwatchData {
                value
                group
                color_group
              }
            }
          }
        }
        variants {
          product {
            uid
            stock_status
            availability_message
            price_range {
              minimum_price {
                regular_price {
                  value
                }
                final_price {
                  value
                }
              }
            }
            name
            sku
            cms_block
            enable_free_fabric_sample
            image {
              label
              disabled
              position
              url(width: 776, height: 576)
            }
            media_gallery {
              position
              url(width: 776, height: 576)
              disabled
              label
            }
            media_gallery {
              position
              url(width: 776, height: 576)
              disabled
              label
            }
          }
          attributes {
            uid
            label
            code
            value_index
          }
        }
      }
    }`

export const productDetailType = ` items {
      stock_status
      enable_free_fabric_sample
      __typename
      productbannermobiletop
      productbanner
      related_products {
        name
        sku
        price_range {
          minimum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
          }
        }
      }
      uid
      name
      sku
      short_description {
        html
      }
      media_gallery {
        label
        disabled
        position
        url(width: 776, height: 576)
      }
      image {
        url(width: 776, height: 576)
        disabled
        position
        label
      }
      cms_block
      description {
        html
      }
      dimensions {
        html
      }
      whyus {
        html
      }
      delivery {
        html
      }
      name
      sku
      availability_message
      price_range {
        minimum_price {
          regular_price {
            value
          }
          final_price {
            value
          }
        }
      }
    }`

export const orderDetailPayloadType = (orderId: string) => ` orders(filter:{number: {eq:"${orderId}"}}) {
        total_count
        items {
          shipping_address{
            street
            firstname
            lastname
            city
            company
            country_code
            fax
            region
            telephone
          }
          items{
            quantity_ordered
            id
            product_url_key
            product_name
            product_sku
            selected_options{
              label
              value
            }
            product_sale_price{
              currency
              value
            }
            status
          }
          id
          increment_id
          order_date
          status
          shipping_method
          payment_methods {
            name
          }
          total {
            subtotal {
              value
            }
            taxes{
              amount{
                currency
                value
              }
              rate
              title
            }
            discounts{
              amount{
                currency
                value
              }
              label
            }
            shipping_handling{
              total_amount{
                currency
                value
              }
              amount_excluding_tax{
                currency
                value
              }
              amount_including_tax{
                currency
                value
              }
              discounts{
                amount{
                  currency
                  value
                }
              }
              taxes{
                amount{
                  currency
                  value
                }
                rate
                title
              }
              
            }
            grand_total {
              value
              currency
            }
          }
        }
      }`

export const blogPayload = `
      author_name
      author_id
      categories
      created_at
      full_content
      is_featured
      list_thumbnail
      list_thumbnail_alt
      post_id
      post_thumbnail
      published_at
      short_content
      status
      title
      updated_at
      url_key`;

export const getCustomerPayload = (isFromHomePage: boolean): string => {
  return isFromHomePage
    ? `{
    customer {
      firstname
      lastname
      email
    }
  }`
    : `{
    customer {
      firstname
      lastname
      email
      is_subscribed
    }
  }`;
};

export const wishlistType = `wishlist {
    items_count
    items {
      id
      product {
        sku
        name
        url_key
        url_suffix
        image {
          label
          url
        }
        price_range {
          minimum_price {
            regular_price {
              value
            }
            final_price {
              value
            }
          }
        }
      }
    }
  }`;

export const getField = (key: string, value: any) => {
  return value ? `${key}: ${value}` : "";
};

export const createAddressPayload = (address: any): string => {
  return `mutation {
  createCustomerAddress(input: {
    ${getField("city", address.city ? '"' + address.city + '"' : null)},
    ${getField(
    "region",
    address.state ? `{ region: "${address.state}"}` : undefined
  )},
    ${getField(
    "firstname",
    address.firstname ? '"' + address.firstname + '"' : null
  )},
    ${getField(
    "lastname",
    address.lastname ? '"' + address.lastname + '"' : null
  )},
    ${getField(
    "company",
    address.company ? '"' + address.company + '"' : null
  )},
    ${getField(
    "telephone",
    address.telephone ? '"' + address.telephone + '"' : null
  )},
    ${getField("street", address.street ? '"' + address.street + '"' : null)},
    ${getField(
    "postcode",
    address.postcode ? '"' + address.postcode + '"' : null
  )},
    country_id: GB,
    default_shipping: true,
    default_billing: true,
  }) {
    id
    ${addressType}
  }}`;
};

export const addressFormType = (address: any) => {
  return `input: {
    firstname: "${address.firstname}",
    lastname: "${address.lastname}",
    company: "${address.company}",
    street: "${address.street}",
    city: "${address.city}",
    ${address.state
      ? `region: {
      region: "${address.state}"
    },`
      : ""
    }
    telephone: "${address.telephone}",
    postcode: "${address.postcode}"
    default_shipping: true,
    default_billing: true,
  }`;
};

export const getUserRequest = (payload: any): string => {
  return payload.password && payload.email
    ? `{
    firstname: "${payload.firstname}"
    lastname: "${payload.lastname}",
    email: "${payload.email}"
    password: "${payload.password}"
  }`
      : `{
    firstname: "${payload.firstname}"
    lastname: "${payload.lastname}"
  }`;
};

export const addressType = `
      id
      firstname
      lastname
      street
      city
      company
      region {
        region_code
        region
      }
      postcode
      country_code
      telephone`;


export const deleteCartPayload = ({ cartId, cartItemId }: any): string => {
  return `
mutation {
  removeItemFromCart(input: { cart_id: "${cartId}" , cart_item_uid: "${cartItemId}" }) {
    cart {
      id
      ${cartType}
    }
  }
}
`;
};

export const addProductToWishlistPayload = ({ sku, quantity }: any): string => {
  return `mutation {
  addProductsToWishlist(
    wishlistId: "0"
    wishlistItems: [{ sku: "${sku}"  quantity: ${quantity} }]
  ) {
    user_errors {
      code
      message
    }
    wishlist {
      id
      items_v2{
        items{
          id
          product{
            name
            sku
          }
        }
      }
    }
  }
}
`;
};

export const removeFromWishlistPayload = ({ wishlistItemId }: any): string => {
  return `
mutation {
  removeProductsFromWishlist(wishlistId: "0", wishlistItemsIds: ["${wishlistItemId}"]) {
    user_errors {
      code
      message
    }
    ${wishlistType}
  }
}
`;
};